/* 
Remove animation from accordion
https://stackoverflow.com/questions/13119912/disable-bootstraps-collapse-open-close-animation
*/
.collapsing {
    -webkit-transition: none;
    transition: none;
    transition-duration:0ms;
    transition-delay:0ms;
    display: none;
}
#sidebar-wrapper {
    position: sticky;
    height: 100vh;
    padding: 0;
    background-color: #f8f9fa;
}

#sidebar-wrapper a {
    color: inherit;
    text-decoration: inherit;
}

#sidebar-header {
    text-align: center;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

#page-wrapper {
    height: 100vh;
}

.nav-selected {
    font-weight: bold;
}

.logo-wrapper {
    width: 100%;
}

#progress-tab {
    padding: 2rem 0.9rem;
}

.progress-label {
    font-size: small;
    text-align: left;
    margin-bottom: 0.2rem;
}

#content-wrapper {
    height: 100vh;
    margin: 10 rem;
    padding-top: 4.6rem;
    padding-left: 5rem;
    padding-right: 10rem;
    overflow-y: auto;
}

.content-wrapper {
    margin-top: 2rem;
    font-size: medium;
}

.button-group {
    margin-top: 1rem;
    padding-bottom: 2rem;
}

.sidebar-button-group {
    display: flex;
}

.link-box {
    width: auto;
    font-size: medium;
}

#preview {
    width: 100%;
    height: 100%;
}

#sidebar-preview {
    margin-top: 1rem;
    width: 100%;
    color: white;
}

#sidebar-preview .btn-danger {
    width: -webkit-fill-available;
    margin: 0.5rem 0.8rem;
    background-color: #dc3545d1;
    border-color: #dc3545d1;
}

#sidebar-preview .btn-secondary {
    width: -webkit-fill-available;
    margin: 0.1rem 0.8rem;
    background-color: #bdb3b3;
    border-color: #bdb3b3;
}


.card-home {
    padding-top: 2rem;
    align-items: center;
    min-height: 12;
    align-self: stretch;
}

.heading-section {
    min-height: 50vh;
    padding: 1rem;
    background: #fdf9f9;
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
}

.heading-text {
    padding-top: 9rem;
}

.heading-text > h1 {
    font-size: 1.99rem;
    padding-bottom: 1.5rem;
}

.heading-section > .logo-wrapper {
    width: 66%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.template {
    display: flex;
    margin: 2rem 4rem;
    /* border: 1px solid red; */
    flex-direction: row;
    justify-content: space-between;
}
.template-nav {
    /* border: 1px solid pink; */
    width: 40rem;
    padding: 0 1rem;
}

.template-card {
    margin: 1rem 0.5rem;
}

.navbar {
    height: 5rem;
    box-shadow: -1px -6px 13px 1px rgb(0 0 0 / 20%);
}

@media only screen and (max-width: 600px) {
    .container-fluid > .row {
        flex-flow: row;
    }

    #content-wrapper {
        height: 100vh;
        padding-top: 1rem;
        padding-left: 0rem;
        padding-right: 0rem;
        overflow-y: auto;
    }

    #sidebar-wrapper {
        width: 32%;
    }

    .heading-section {
        flex-direction: column;
    }

    .heading-section > .logo-wrapper {
        width: 100%;
        margin-bottom: 1rem;
        padding-left: 0px;
        padding-right: 0px;
    }

    .template {
        flex-direction: column-reverse;
        margin: 0px;
    }

    .template-nav {
        width: auto;
        margin-bottom: 1rem;
    }

    .heading-text {
        padding-top: 1rem;
    }

    .heading-text > h1 {
        font-size: 1.5rem;
    }

    .template-content > .container > .row > h3 {
        font-size: 1.2rem;
    }
}
